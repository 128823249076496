<template>
    <modal show="show" :modalLarge="true" v-if="isValid('CanAddColor') || isValid('CanEditColor')">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel" v-if="type == 'Edit'">Update Template</h6>
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel" v-else>Add template</h6>
                <button type="button" class="btn-close" v-on:click="close()"></button>
                <a v-on:click="PrintView()" href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                        View
                    </a>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div v-if="english == 'true'" class="form-group has-label col-sm-6 ">
                        <label class="text  font-weight-bolder">Main Heading:<span class="text-danger"> *</span> </label>
                        <input class="form-control" v-model="$v.color.name.$model" />
                        <span v-if="$v.color.name.$error" class="error">
                            <span v-if="!$v.color.name.required"> {{ $t('AddColors.NameRequired') }}</span>
                            <span v-if="!$v.color.name.maxLength">{{ $t('AddColors.NameRequired') }}</span>
                        </span>
                    </div>
                    <div class="form-group has-label col-sm-6 ">
                        <label>Customer: </label>

                        <multiselect :options="options" @input="GetRecord" :multiple="false" track-by="name"
                                ref="multiselect" :searchable="true" :clear-on-select="false" :show-labels="false"
                                label="name" :preselect-first="true"
                                v-bind:placeholder="$t('CustomerDropdown.SelectOption')">
                            </multiselect>
                    </div>



                    <div class="form-group has-label col-sm-8 ">
                        <label>Main Paragraph: </label>
                            <VueEditor v-model="color.description" ref="editor" @drop="onDropToEditor"  />
                    </div>
                    <div class="col-md-4">
                            <label>Record: </label>
                            <div class="card">
                                <div class="card-body">
                                    <template v-for="(record, index) in optionsList">
                                        <div v-if="record && record.trim() !== ''" class="badge badge-soft-primary mt-1 mx-2"
                                            :key="index" draggable="true" @dragstart="onDragStart(record, $event)">
                                            {{ record }}
                                        </div>
                                    </template>
                                </div>

                            </div>

                        </div>


                    <div class="form-group col-md-4">
                        <div class="checkbox form-check-inline mx-2">
                            <input type="checkbox" id="inlineCheckbox1" v-model="color.isActive">
                            <label for="inlineCheckbox1"> {{ $t('AddColors.Active') }} </label>
                        </div>
                    </div>



                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveColor"
                    v-bind:disabled="$v.color.$invalid" v-if="type != 'Edit' && isValid('CanAddColor')">{{
                        $t('AddColors.btnSave') }}</button>
                <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveColor"
                    v-bind:disabled="$v.color.$invalid" v-if="type == 'Edit' && isValid('CanEditColor')">{{
                        $t('AddColors.btnUpdate') }}</button>
                <button type="button" class="btn btn-soft-secondary btn-sm" v-on:click="close()">{{ $t('AddColors.btnClear')
                }}</button>
            </div>
            <contractor-view :contractor="color" :show="show12" v-if="show12" @close="show12 = false"></contractor-view>

            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
        </div>



    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>


<script>
import clickMixin from '@/Mixins/clickMixin'
import Multiselect from 'vue-multiselect'
import { VueEditor } from "vue2-editor";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { required, maxLength } from "vuelidate/lib/validators"
export default {
    props: ['show', 'color', 'type'],
    mixins: [clickMixin],
    components: {
        Loading,
        Multiselect,
        VueEditor,
    },
    data: function () {
        return {
            optionsList: ['$Name', '$RegistrationDate', '$PhoneNo', '$TelephoneNo', '$Email', '$VatNo', '$Address', '$Currency'],
            arabic: '',
            options: [],
            customer: '',
            english: '',
            render: 0,
            loading: false,
            show12: false,
        }
    },
    validations: {
        color: {
            name: {

                maxLength: maxLength(250)
            },
            nameArabic: {
                
                maxLength: maxLength(250)
            },
            code: {
                maxLength: maxLength(30)
            },
            description: {
                required
            }
        }
    },
    methods: {
        PrintView: function () {
            this.show12 = true;

        },
        close: function () {
            this.$emit('close');
        },
        GetAutoCodeGenerator: function () {

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Product/ColorCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    root.color.code = response.data;
                    root.render++;
                }
            });
        },
        SaveColor: function () {
            var root = this;
            this.loading = true;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Product/SaveColor', this.color, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data.isSuccess == true) {

                        if (root.type != "Edit") {

                            root.$swal({
                                title: root.$t('AddColors.SavedSuccessfully'),
                                text: root.$t('AddColors.Saved'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });

                            root.close();
                        }
                        else {

                            root.$swal({
                                title: root.$t('AddColors.SavedSuccessfully'),
                                text: root.$t('AddColors.UpdateSucessfully'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: root.$t('AddColors.Error'),
                            text: root.$t('AddColors.YourColorNameAlreadyExist'),
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: this.$t('AddColors.SomethingWrong'),
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },
        GetRecord: function (selectedOption) {
        // Assuming selectedCustomer contains the object of the selected customer
        const selectedCustomer = selectedOption /* Logic to fetch the selected customer object */;

        // Get the reference to the VueEditor
        const editor = this.$refs.editor;

        // Get the current content of the VueEditor
        const editorContent = editor.quill.getContents();

        // Loop through each key in the selected customer object
        for (const key in selectedCustomer) {
            if (Object.hasOwnProperty.call(selectedCustomer, key)) {
                const regex = new RegExp(`\\b${key}\\b`, 'g'); // Create a regex to match whole words
                const replacement = selectedCustomer[key];

                // Replace occurrences of the key with the corresponding value in the editor content
                editorContent.ops.forEach(op => {
                    if (typeof op.insert === 'string' && regex.test(op.insert)) {
                        op.insert = op.insert.replace(regex, replacement);
                    }
                });
            }
        }

        // Set the updated content back to the VueEditor
        editor.quill.setContents(editorContent);
    },
        // Function triggered when a record is dropped into the text area
     
        // Function triggered when a record is dropped onto the VueEditor
        onDragStart(record, event) {
            event.dataTransfer.setData('text/plain', record);
            event.dataTransfer.setData('text/identifier', record.id); // Use a unique identifier if available

            // Clear the previous selection in the VueEditor
            const editor = this.$refs.editor;
            editor.quill.blur(); // Remove focus to clear the selection
        },

        // Function triggered when a record is dropped onto the VueEditor
        onDropToEditor(event) {
            debugger;
            event.preventDefault();
            const data = event.dataTransfer.getData('text/plain');
            const identifier = event.dataTransfer.getData('text/identifier'); // Retrieve the identifier
            const editor = this.$refs.editor;
            if (identifier) {
                // Insert the dropped content into the VueEditor based on the identifier
                // Adjust this logic based on how your data is structured
                editor.quill.insertText(editor.quill.getSelection().index, `${data}\n`);
            }
            event.dataTransfer.clearData(); // Clear the data after drop
        },
      

        getImage: function (value) {
            this.contractor.logoPath = value;
            this.isDelete = true;
        },


       
        getData: function () {


            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }




            this.$https.get('/Contact/ContactList?IsDropDown=' + true + '&isCustomer=' + true + '&isActive=' + true + '&paymentTerms=' + '' + '&searchTerm=' + '' + '&isCashCustomer=' + false + '&multipleAddress=' + false, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                if (response.data != null) {
                    response.data.results.forEach(function (cust) {
                        if (cust.contactNo1 == null)
                            cust.contactNo1 = '';
                        if (cust.customerCode == null)
                            cust.customerCode = '';




                        root.options.push({
                            name: cust.englishName,
                            commercialRegistrationNo: cust.commercialRegistrationNo,
                            vatNo: cust.vatNo,
                            contactNo1: cust.contactNo1,
                            email: cust.email,
                            address: cust.address,
                            registrationDate: cust.registrationDate,
                        });

                    });


                }
            }).then(function () {

                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                })

            });
        },



    },
    mounted: function () {
        this.getData();

        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
       

    }
}
</script>
